export const isProd = process.env.NODE_ENV === "production";
export const REACT_URL = window.location.host;
export const TOKEN_KEY = `user_token`;

/**
 * getEnvironment
 * @returns {{
 * storage: string,
 * server: string,
 * agentURL: string,
 * adminURL: string,
 * defaultBanner: string
 *  }} Objeto que contiene las diferentes variables de entorno.
 */
export function getEnvironment() {
  let environment = {
    local: {
      storage: "http://localhost:3003",
      server: "http://localhost:3001",
      agentURL: "http://localhost:4003",
      adminURL: "http://localhost:4002",
      defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
    },
    dev: {
      storage: "https://cdn.dev.li.omniflou.com",
      server: "https://api.dev.omniflou.com",
      agentURL: "https://agent.dev.omniflou.com",
      adminURL: "https://admin.dev.omniflou.com",
      defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
    },
    qa: {
      storage: "https://cdn.qa.li.omniflou.com",
      server: "https://api.qa.omniflou.com",
      agentURL: "https://agent.qa.omniflou.com",
      adminURL: "https://admin.qa.omniflou.com",
      defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
    },
    staging: {
      storage: "",
      server: "",
      agentURL: "",
      adminURL: "",
      defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
    },
    production: {
      storage: "https://cdn.li.omniflou.com",
      server: "https://api.omniflou.com",
      agentURL: "https://agent.omniflou.com",
      adminURL: "https://admin.omniflou.com",
      defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
    },
  };

  // let env = "development";
  // const customEnv = process.env.REACT_APP_ENV || "";
  // switch (customEnv) {
  //   case "production":
  //     env = "production";
  //     break;
  //   case "qa":
  //     env = "qa";
  //     break;
  //   case "staging":
  //     env = "staging";
  //     break;
  //   case "dev":
  //     env = "dev";
  //     break;
  //   default:
  //     env = process.env.NODE_ENV;
  //     break;
  // }

  return environment[process.env.REACT_APP_ENV] ?? environment["local"];
}

export const environment = getEnvironment();
// getEnvironment() === "production"
//   ? {
//       storage: "https://cdn.videolink.app",
//       server: "https://demoserverapi.videolink.app",
//       agentURL: "https://agent.videolink.app",
//       adminURL: "https://admin.videolink.app",
//       defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
//     }
//   : getEnvironment() === "qa"
//   ? {
//       storage: "https://cdn.dev.videolink.app",
//       server: "https://api.qa.videolink.app",
//       agentURL: "https://agent.qa.videolink.app",
//       adminURL: "https://adminv2.qa.videolink.app",
//       defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
//     }
//   : getEnvironment() === "dev" || getEnvironment() === "development"
//   ? {
//       storage: "https://cdn.dev.videolink.app",
//       server: "https://api.dev.videolink.app",
//       agentURL: "https://agent.dev.videolink.app",
//       adminURL: "https://adminv2.dev.videolink.app",
//       defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
//     }
//   : {
//       storage: "https://cdn.dev.videolink.app",
//       server: "http://localhost:3003",
//       agentURL: "https://agent.qa.videolink.app",
//       adminURL: "https://adminv2.qa.videolink.app",
//       defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
//     };

function getServerHost() {
  const apiUrl = environment.server;

  if (apiUrl) {
    return apiUrl;
  } else {
    return isProd
      ? `http://${window.location.host}`
      : `http://${window.location.hostname}:3003`;
  }
}

//URL
export const SERVER_HOST = getServerHost();

export const API_BASE_URL = `${SERVER_HOST}`;
